import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
					<path d="M5160 8429 c-560 -54 -1080 -281 -1504 -658 -109 -96 -211 -203 -304
-320 -66 -83 -192 -266 -192 -280 0 -7 489 -11 1583 -14 1436 -2 1590 -4 1672
-19 352 -67 566 -212 695 -473 83 -169 95 -226 95 -460 -1 -192 -2 -203 -29
-285 -68 -205 -163 -344 -309 -452 -126 -94 -240 -139 -457 -179 -110 -21
-150 -22 -851 -26 l-736 -4 -7 -197 c-8 -254 -8 -903 0 -1010 4 -48 2 -87 -4
-95 -5 -7 -11 -154 -13 -361 l-4 -350 30 -8 c65 -18 273 -48 415 -59 421 -34
909 60 1307 253 518 250 945 667 1205 1174 209 407 298 806 285 1278 -6 228
-24 363 -78 580 -172 687 -649 1304 -1274 1647 -204 112 -494 222 -706 268
-248 53 -578 73 -819 50z m1575 -564 c121 -22 143 -29 226 -67 197 -91 361
-253 448 -442 26 -56 21 -98 -12 -121 -50 -35 -94 -10 -138 78 -41 80 -62 109
-133 185 -74 77 -183 146 -295 186 -82 29 -94 31 -236 31 -128 0 -161 -3 -222
-23 -44 -13 -83 -20 -98 -16 -52 13 -72 82 -35 122 22 23 127 58 217 71 92 14
192 12 278 -4z m-2 -270 c133 -35 274 -120 338 -204 19 -25 39 -52 46 -59 7
-7 26 -41 43 -76 34 -70 34 -105 -3 -131 -51 -36 -98 -14 -134 63 -36 78 -139
179 -223 219 -112 54 -227 62 -362 26 -74 -20 -104 -12 -125 32 -14 30 -15 38
-3 59 14 28 61 53 130 72 63 18 224 17 293 -1z m22 -293 c73 -37 134 -100 171
-178 25 -53 26 -60 13 -83 -8 -14 -28 -32 -44 -40 -25 -12 -32 -12 -62 4 -18
10 -33 23 -33 29 0 30 -68 108 -114 131 -58 29 -101 31 -174 10 -47 -14 -55
-14 -82 0 -34 17 -47 55 -34 94 7 19 25 32 64 49 47 19 68 22 145 19 76 -3 99
-9 150 -35z"/>
					<path d="M2905 6627 c-19 -53 -67 -237 -79 -306 -45 -238 -60 -585 -35 -796
51 -436 188 -814 427 -1180 130 -197 410 -496 594 -633 114 -85 257 -177 264
-170 4 5 23 1605 24 2036 l0 212 261 0 c157 0 278 -4 304 -11 53 -13 1351 5
1455 21 125 18 198 47 255 101 160 151 165 459 10 613 -68 68 -150 97 -325
116 -151 15 -3149 13 -3155 -3z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
